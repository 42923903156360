import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

const Sponsor = ({
	name,
	imageData,
	description,
	reverse,
	hasLink,
	link,
	section,
}) => (
	<Row className="align-items-center gy-4 pb-5 pb-lg-8  gx-lg-8 sponsor-row">
		<Col
			md={{
				span: 6,
				order: reverse ? "last" : "first", // This will change the order based on the 'reverse' prop
			}}
			className="mb-3 mb-md-0"
		>
			{hasLink !== true && (
				<div className="p-4 bg-white">
					<GatsbyImage image={imageData} alt={`${name} Logo`} />
				</div>
			)}
			{hasLink === true && (
				<a rel="noreferrer" target="_blank" href={link}>
					<div className="p-4 bg-white">
						<GatsbyImage image={imageData} alt={`${name} Logo`} />
					</div>
				</a>
			)}
		</Col>
		<Col
			md={{
				span: 6,
				order: reverse ? "first" : "last", // Text will be ordered first if reverse is true
			}}
		>
			<div className="sponsor-detail text-white">
				<h2 className="fs-5">{section}</h2>
				<h3 className="fs-2 pb-3">{name}</h3>
				<p>{description}</p>
			</div>
		</Col>
	</Row>
);

const SponsorsSection = ({ sponsors, section }) => {
	return (
		<Container>
			{sponsors.map((sponsor, index) => (
				<Sponsor
					section={section}
					key={sponsor.id}
					name={sponsor.title}
					imageData={
						sponsor.sponsorFields?.logo.localFile.childImageSharp
							.gatsbyImageData
					}
					hasLink={sponsor.sponsorFields?.hasLink}
					link={sponsor.sponsorFields?.linkUrl}
					description={sponsor.sponsorFields?.description}
					reverse={index % 2 !== 0} // This will reverse the order for every other sponsor
				/>
			))}
		</Container>
	);
};

export default SponsorsSection;

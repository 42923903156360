import { Link, useStaticQuery, graphql } from "gatsby";
import React, { useEffect, useRef } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Layout from "../components/layout";
import Button from "react-bootstrap/Button";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import { Helmet } from "react-helmet";
import BottomBannerLeft from "../components/bottom-banner-left";
import SvgFanDarkSo from "../components/svg/fan-dark-svg-so";
import SvgFanLightSo from "../components/svg/fan-light-svg-so";
import SvgFanLightNoPink from "../components/svg/fan-light-no-pink";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import useOnScreen from "../components/use-on-screen";
import gsap from "gsap";
import HubspotForm from "react-hubspot-form";
import SponsorsSection from "../components/sponsors";
const AllSponsorsPage = () => {
	const data = useStaticQuery(graphql`
		query {
			heroImg: wpMediaItem(title: { eq: "Home-Hero-Image" }) {
				altText
				localFile {
					publicURL
					childImageSharp {
						original {
							height
							width
						}
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 100
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}

			headlineSponsor: allWpSponser(
				filter: { sponsorFields: { section: { eq: "Headline Sponsor" } } }
				limit: 1
			) {
				nodes {
					title
					sponsorFields {
						description
						hasLink
						linkUrl
						section
						logo {
							altText
							localFile {
								childImageSharp {
									gatsbyImageData(
										formats: [AUTO, WEBP]
										quality: 100
										transformOptions: { cropFocus: CENTER, fit: CONTAIN }
										layout: CONSTRAINED
										placeholder: BLURRED
									)
								}
							}
						}
					}
				}
			}
			platinumSponsors: allWpSponser(
				filter: { sponsorFields: { section: { eq: "Platinum Sponsor" } } }
			) {
				nodes {
					title
					sponsorFields {
						description
						hasLink
						linkUrl
						section
						logo {
							altText
							localFile {
								childImageSharp {
									gatsbyImageData(
										formats: [AUTO, WEBP]
										quality: 100
										transformOptions: { cropFocus: CENTER, fit: CONTAIN }
										layout: CONSTRAINED
										placeholder: BLURRED
									)
								}
							}
						}
					}
				}
			}
			goldSponsors: allWpSponser(
				filter: { sponsorFields: { section: { eq: "Gold Sponsor" } } }
			) {
				nodes {
					title
					sponsorFields {
						description
						hasLink
						linkUrl
						section
						logo {
							altText
							localFile {
								childImageSharp {
									gatsbyImageData(
										formats: [AUTO, WEBP]
										quality: 100
										transformOptions: { cropFocus: CENTER, fit: CONTAIN }
										layout: CONSTRAINED
										placeholder: BLURRED
									)
								}
							}
						}
					}
				}
			}
			eventSponsors: allWpSponser(
				filter: { sponsorFields: { section: { eq: "Event Sponsor" } } }
			) {
				nodes {
					title
					sponsorFields {
						description
						hasLink
						linkUrl
						section
						logo {
							altText
							localFile {
								childImageSharp {
									gatsbyImageData(
										formats: [AUTO, WEBP]
										quality: 100
										height: 150
										transformOptions: { cropFocus: CENTER, fit: CONTAIN }
										layout: CONSTRAINED
										placeholder: BLURRED
									)
								}
							}
						}
					}
				}
			}
			site {
				siteMetadata {
					siteUrl
				}
			}
		}
	`);

	const siteUrl = data.site.siteMetadata.siteUrl;

	const logoImg = data.logoImg?.localFile.childImageSharp.gatsbyImageData;

	const breadcrumb = {
		"@context": "http://schema.org",
		"@type": "BreadcrumbList",
		itemListElement: [
			{
				"@type": "ListItem",
				position: "1",
				name: "Home",
				item: {
					url: `${siteUrl}`,
					id: `${siteUrl}`,
				},
			},
			{
				"@type": "ListItem",
				position: "2",
				name: "All Sponsors",
				item: {
					url: `${siteUrl}/all-sponsors`,
					id: `${siteUrl}/all-sponsors`,
				},
			},
		],
	};

	return (
		<Layout>
			<Helmet>
				{" "}
				<script type="application/ld+json">{JSON.stringify(breadcrumb)}</script>
			</Helmet>

			<GatsbySeo
				title="All Sponsors | Eastbourne Carnival"
				description="A huge thank you to all of our sponsors"
				language="en"
				openGraph={{
					type: "website",
					url: `${siteUrl}/all-sponsors`,
					title: "All Sponsors | Eastbourne Carnival",
					description: "A huge thank you to all of our sponsors",
					images: [
						{
							url: `${data.heroImg?.localFile.publicURL}`,
							width: `${data.heroImg?.localFile.childImageSharp.original.width}`,
							height: `${data.heroImg?.localFile.childImageSharp.original.height}`,
							alt: `${data.heroImg?.altText}`,
						},
					],
				}}
			/>

			<section className="pt-5 pt-lg-8  pb-5  position-relative">
				<Container>
					<Row className="justify-content-center pb-7">
						<Col lg={8}>
							<h1 className=" text-center display-5 pb-4 carnival-bold text-secondary">
								A huge thank you to all of our sponsors!
							</h1>
						</Col>
					</Row>
				</Container>
			</section>
			{data.headlineSponsor?.nodes?.length > 0 && (
				<section className="py-5 py-lg-8" style={{ background: "#44164D" }}>
					<Container>
						<Row className="align-items-center gy-4 gx-lg-8 ">
							<Col
								md={{
									span: 6,
									order: "first", // This will change the order based on the 'reverse' prop
								}}
								className="mb-3 mb-md-0"
							>
								{data.headlineSponsor.nodes[0].sponsorFields?.hasLink !==
									true && (
									<div className="p-4 bg-white">
										<GatsbyImage
											image={
												data.headlineSponsor.nodes[0].sponsorFields.logo
													.localFile.childImageSharp.gatsbyImageData
											}
											alt={`${data.headlineSponsor.nodes[0].title} Logo`}
										/>
									</div>
								)}
								{data.headlineSponsor.nodes[0].sponsorFields?.hasLink ===
									true && (
									<a
										rel="noreferrer"
										target="_blank"
										href={data.headlineSponsor.nodes[0].sponsorFields?.linkUrl}
									>
										<div className="p-4 bg-white">
											<GatsbyImage
												image={
													data.headlineSponsor.nodes[0].sponsorFields.logo
														.localFile.childImageSharp.gatsbyImageData
												}
												alt={`${data.headlineSponsor.nodes[0].title} Logo`}
											/>
										</div>
									</a>
								)}
							</Col>
							<Col
								md={{
									span: 6,
									order: "last", // Text will be ordered first if reverse is true
								}}
							>
								<div className="sponsor-detail text-white">
									<h2 className="fs-5">Headline Sponsor</h2>
									<h3 className="fs-2 pb-3">
										{data.headlineSponsor.nodes[0].title}
									</h3>
									<p>
										{data.headlineSponsor.nodes[0].sponsorFields?.description}
									</p>
								</div>
							</Col>
						</Row>
					</Container>
				</section>
			)}
			{data.platinumSponsors?.nodes?.length > 0 && (
				<section className="pt-5 pt-lg-8" style={{ background: "#A73182" }}>
					<Container>
						<SponsorsSection
							sponsors={data.platinumSponsors.nodes}
							section="Platinum Sponsor"
						/>
					</Container>
				</section>
			)}
			{data.goldSponsors?.nodes?.length > 0 && (
				<section className="pt-5 pt-lg-8" style={{ background: "#4787C8" }}>
					<Container>
						<SponsorsSection
							sponsors={data.goldSponsors.nodes}
							section="Gold Sponsor"
						/>
					</Container>
				</section>
			)}
			<section className="py-5 py-lg-8 " style={{ background: "#3AB548" }}>
				<Container className="pb-lg-10">
					<Row>
						<Col className="text-center">
							<h2 className="text-white fs-1 pb-4">Event Sponsors</h2>
							<p className="text-white ">
								Join us in celebrating the vibrant spirit of Eastbourne by
								becoming a sponsor of the Eastbourne Carnival!
							</p>
							<p className="text-white ">
								As a sponsor, you have the opportunity to support a cherished
								community event that brings to life the joy and creativity of
								Eastbourne. Your contribution will not only help in creating an
								unforgettable experience for the carnival attendees but also
								showcase your commitment to the local community.{" "}
							</p>
							<p className="text-white pb-4">
								Let's work together to make this years 'Into The Wild' carnival,
								a spectacular celebration of our natural environment and the
								creative spirit of Eastbourne.{" "}
							</p>
						</Col>
					</Row>
					<Row className="g-4 gx-lg-5 align-items-center">
						{data?.eventSponsors?.nodes?.map((sponsor, index) => (
							<Col
								xs={6}
								md={{
									span: 4,
								}}
								lg={3}
								className="mb-3 mb-md-0 text-center"
							>
								{sponsor.sponsorFields.hasLink !== true && (
									<div
										style={{ height: "198px" }}
										className="p-4 d-flex align-items-center justify-content-center bg-white"
									>
										<GatsbyImage
											image={
												sponsor.sponsorFields.logo.localFile.childImageSharp
													.gatsbyImageData
											}
											alt={`${sponsor.title} Logo`}
										/>
									</div>
								)}
								{sponsor.sponsorFields.hasLink === true && (
									<a
										rel="noreferrer"
										target="_blank"
										href={sponsor.sponsorFields.linkUrl}
									>
										{" "}
										<div
											style={{ height: "198px" }}
											className="p-4 d-flex align-items-center justify-content-center bg-white"
										>
											<GatsbyImage
												image={
													sponsor.sponsorFields.logo.localFile.childImageSharp
														.gatsbyImageData
												}
												alt={`${sponsor.title} Logo`}
											/>
										</div>
									</a>
								)}
							</Col>
						))}
					</Row>
				</Container>
			</section>
			<BottomBannerLeft
				mainBg="bg-lightBlue"
				position="top--40"
				content={
					<Row>
						<Col className="py-5 py-md-0">
							<h2 className="text-white text-center mt-md-5  pt-md-10 fs-1 shortstack">
								GET IN TOUCH TO REGISTER YOUR INTEREST
							</h2>
							<div className="d-flex pt-5 justify-content-center align-items-center">
								<Button
									variant="outline-white"
									className="fs-5 py-3  px-4 jost-normal"
									as={Link}
									to="/sponsorship-opportunities"
								>
									CONTACT US
								</Button>
							</div>
						</Col>
					</Row>
				}
			/>
		</Layout>
	);
};

export default AllSponsorsPage;
